import React, { useState } from 'react';
import {
    Container,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText,
    Button,
} from 'react-bootstrap';
import Logo from '../assets/KBEND-removebg.png'
import '../styles/Navbar.css'
import ContactButton from "./ContactButton";
import {MDBIcon} from "mdbreact";


const Navi = (props) => {


    return (
        <div className="center">
            <Container>
            <Navbar bg="" variant="dark">
                <Navbar.Brand href="/">
                    <img
                        alt=""
                        src={Logo}
                        width="120"
                        height="120"
                    />{''}
                </Navbar.Brand>
                <div className="center"> </div>

                <NavLink style={{color: '#fff'}} href="/about">Hakkımızda</NavLink>
                <NavLink style={{color: '#fff'}} href="/services">Servislerimiz</NavLink>
                <NavLink style={{color: '#fff'}} href="/services">Referanslarımız</NavLink>
                <NavLink>
                    {/*<Button style={{backgroundColor:'#9b10e9'}} href="/contact"  onClick={(e) => {
                    e.preventDefault();
                    window.location.href='/contact';
                }}>İletişime Geçin</Button>{' '}*/}
                <ContactButton/>
                </NavLink>
                <a href="https://www.instagram.com/balikesirkbdanismanlik/?hl=tr" className="ins-ic mr-3">
                    <MDBIcon fab icon="instagram" />
                </a>
                <a href="https://www.facebook.com/113341967517029/posts/113343757516850/?substory_index=0&sfnsn=scwspwa" className="fb-ic mr-3">
                    <MDBIcon fab icon="facebook-f" />
                </a>
                <a href="https://www.linkedin.com/company/balikesirkbdanismanlik" className="li-ic mr-3">
                    <MDBIcon fab icon="linkedin-in" />
                </a>
            </Navbar>
            </Container>
        </div>
    );
}

export default Navi;
