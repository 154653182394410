import '../styles/Main.css'
import React, {useState} from "react";
import {
    Container,
    Card,
    Form,
    Button,
    Col,Row} from "react-bootstrap";

import Logo from '../assets/KBEND.png';
import styled, { keyframes } from 'styled-components';
import { fadeInLeft, fadeInRight } from 'react-animations';
import image1 from '../assets/elderly-care.jpeg';
import image2 from '../assets/Japan-has-the-world’s-oldest-population.-Yet-it-dodged-a-coronavirus-crisis-at-elder-care-facilities..jpg';
import image3 from '../assets/caregiver-assisting-senior-man-out-of-bed.jpg';
import image4 from '../assets/pexels-andrea-piacquadio-3768131.jpg';
import image5 from '../assets/woman-with-cane-and-waving-man-illustration_white_background_x2.png';
import Carousel, { slidesToShowPlugin }  from "@brainhubeu/react-carousel";

import "@brainhubeu/react-carousel/lib/style.css";
import useWindowDimensions from "../Components/useWindowDimensions";


const right = keyframes`${fadeInRight}`;
const bounceAnimation = keyframes`${fadeInLeft}`;

const BouncyDiv = styled.div`
  animation: 5s ${bounceAnimation};
`;

const FadeRightDiv = styled.div`
  animation: 5s ${right};
`;


const Main = (props) => {


    const { height, width } = useWindowDimensions();
    return (
        <div>

            <div className="carousel-top-rec2"/>
            <div className="carousel-top-rec3"/>
            <div>
                {width >900 ? <Carousel
                    infinite
                    arrows>
                    <div>
                        <img style={{width:1000,height:563}} src={image3} />

                        <div className="text-boxed">
                            <div className="center-text">
                                <h3 style={{textAlign:'center'}}>Müşterilerimiz için değil Anne Babalarımız için</h3>
                            </div>
                        </div>

                    </div>
                    <div>
                        <img style={{width:1000,height:563}} src={image2} />

                        <div className="text-boxed">
                            <div className="center-text">
                                <h3 style={{textAlign:'center'}}>Sizin İstekleriniz Doğrultusunda</h3>
                            </div>
                        </div>

                    </div>
                    <img src={Logo} />
                </Carousel> : <div/>}
            </div>
            <div className="carousel-top-rec3"/>
            <div className="carousel-top-rec2"/>




            <Container style={styles.container}>


                <BouncyDiv style={{marginBottom:60}}>
                    <div className="text-boxed">
                        <div className="center-text">
                            <h1 className="Texx">Sizin İçin Çalışıyoruz</h1>
                            <p className="par-text"> Bütünleşik ve yenilikçi bir anlayışla rehabilitasyon,
                                evde sağlık ve palyatif bakım hizmeti alanlarında çözüm ortaklarımız ile özel
                                hemşire, fizyoterapist, psikolog ve hasta bakıcı ve diğer profesyonellerine
                                ulaşmanızı sağlayarak evde yaşayan felç geçirmiş, demans, yaşlı veya engelli
                                yakınınız için VIP baştan-sona bakım danışmanlık hizmeti sunmaktadır.</p>
                        </div>
                    </div>
                </BouncyDiv>

                {width>900 ?
                    <img src={image2} style={{display:'block',marginLeft:'auto',marginRight:'auto',width:'50%',borderRadius:2}}/>
                    :
                    <img src={image2} style={{display:'block',marginLeft:'auto',marginRight:'auto',width:'100%',borderRadius:2}}/>
                }





                    <div className="card-container">
                        <div className="one"></div>
                        <div className="left-card">
                            <BouncyDiv>
                                <img className="info-image" src={image1}/>
                            </BouncyDiv>
                        </div>

                        <div className="right-card">
                            <FadeRightDiv>
                                <p className="card-text">
                                    Bireyin sağlığını korumak, yükseltmek, yeniden sağlığına kavuşturmak amacıyla sağlık
                                    ve
                                    sosyal hizmetlerin profesyonel düzeyde veya aile bireyleri tarafından, bireyin kendi
                                    evinde ya
                                    da yaşadığı sizin ve sevdikleriniz için çalışıyoruz.
                                </p>
                            </FadeRightDiv>
                        </div>

                    </div>


                    <div className="card-container">
                        <div className="one"></div>
                        <div className="left-card">
                            <p className="card-text">
                                Evde bakım hizmeti, hekimlerin önerileri doğrultusunda hasta kişilere, aileleri ile
                                yaşadıkları
                                ortamda, sağlık ekibi tarafından rehabilitasyon, fizyoterapi, psikolojik tedavi de dahil
                                tıbbi
                                ihtiyaçlarını karşılayacak şekilde sağlık ve bakım ile takip hizmetlerinin sunuyoruz.
                            </p>
                        </div>
                        <div className="right-card">
                            <img className="info-image" src={image5}/>
                        </div>

                    </div>

                    <div className="card-container">
                    <div className="one"></div>
                    <div className="left-card">
                    <img className="info-image" src={image4}/>
                    </div>
                    <div className="right-card">
                    <p className="card-text">
                    Engelli, yaşlı, kronik hastalığı olan veya iyileşme dönemindeki bireyleri
                    bulundukları ortamda destekleyerek,
                    sosyal yaşama ayak uydurabilmelerini sağlamak,
                    yaşamlarını mutlu ve huzurlu bir biçimde sürdürerek toplumsal entegrasyonlarını
                    gerçekleştirmek,
                    bu misyonlar doğrultusunda sizler için çalışıyoruz.
                    </p>

                    </div>

                    </div>
            </Container>


        </div>
    );
}

const styles={
    image:{
        width: '50%',
        height: '50%',
        borderRadius : '1%',
    },
    text:{
        textAlign: 'center',
        color: '#000'
    },
    container:{
        marginTop: 50,
    }
}

export default Main;
