import {MDBCol, MDBContainer, MDBFooter, MDBRow, MDBIcon} from "mdbreact";
import React, { useState } from 'react';
import {
    Carousel,
    Container,
    Collapse,
} from 'react-bootstrap';
import Logo from '../assets/KBEND-removebg.png'
import Logo2 from '../assets/Japan-has-the-world’s-oldest-population.-Yet-it-dodged-a-coronavirus-crisis-at-elder-care-facilities..jpg';
import 'mdbreact/dist/css/mdb.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import useWindowDimensions from "./useWindowDimensions";
import '../styles/MobileFooter.css';
import Homeicon from '../assets/icons/icons8-home-64.png';
import info from '../assets/icons/icons8-info-64.png';
import services from '../assets/icons/icons8-services-64.png';
import contact from '../assets/icons/icons8-contact-64.png';
const style = {
    backgroundColor: "#120928",
    borderTop: "1px solid #E7E7E7",
    position: "fixed",
    bottom: "0",
    left: "0",
    height: "70px",
    width: "100%",
};

const phantom = {

    height: '60px',
    width: '100%',
};
const text= {

}

const MobileFooter = (props) => {
    const { height, width } = useWindowDimensions();
    return (
        <div>

            <div className="navbar-t">
                <ul style={{marginTop:0}}>
                    <li>
                        <a href="/" className="active">
                            <img src={Homeicon} style={{width:30, height:30}}/>
                        </a>
                        <a href="/" className="active">
                            Ana Sayfa
                        </a>
                    </li>
                    <li>
                        <a href="/about" className="active">
                            <img src={info} style={{width:30, height:30}} href="/about" />
                        </a>
                        <a href="/about" className="active">Hakkımızda</a>
                    </li>
                    <li>
                        <a href="/services" className="active">
                            <img src={services} style={{width:30, height:30}} href="/services"/>
                        </a>
                        <a href="/services" className="active">Servislerimiz</a>
                    </li>
                    <li>
                        <a href="/contact" className="active">
                            <img src={contact} style={{width:30, height:30}} href="/contact"/>
                        </a>
                        <a href="/contact" className="active">İletişim</a>
                    </li>
                </ul>
            </div>

        </div>
    );
}

const styles={
    image:{
        width: '50%',
        height: '50%',
        borderRadius : '1%',
    },
    text:{
        textAlign: 'center',
        color: '#000'
    },
    container:{
        marginTop: 50,
    }
}

export default MobileFooter;


