import React, { useState } from 'react';
import {
    Container,
} from 'react-bootstrap';
import Logo from '../assets/elderly-care.jpeg'
import '../styles/Navbar.css'
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import aboutlogo from '../assets/Yocosafe.ec9c4784.jpg';
import textbg from '../assets/aboutbg.jpeg';
import '../styles/About.css'
import useWindowDimensions from "../Components/useWindowDimensions";

const About = (props) => {
    const { height, width } = useWindowDimensions();

    return (
        <div>
        {width > 900 ?
        <div className="row-about">
            <div className="column-about">
                <img style={{width:'100%'}} src={aboutlogo}/>
            </div>
            <div className="column-about" style={{background:'#a2e'}}>
                <p style={{textAlign:"center"}} className="about-text">Balıkesir'de bakıcılık ve refakat hizmetleri sektöründe büyük bir
                    boşluğu doldurmak amacıyla kurulan firmamız, Sağlık sektöründeki 10 yıllık deneyimi ile hastalarınızı rahat ettirmek,
                    sevdiklerinizi iyi hissettirmek amacıyla profesyonel hayata adımını atmıştır. Profesyonel bakıcı ekibi, Balıkesir ve
                    çevresindeki sağlık hizmeti sektöründe eşsiz deneyimi ile sizleri hiç yormadan tam zamanlı, yarı zamanlı Hasta
                    Bakıcılık ve Refakatçilik Hizmeti vermekteyiz. Bize ulaşabileceğiniz telefon numaraları
                    <strong> 90 545 183 10 10 - 90 545 183 10 11</strong>
                </p>
            </div>
        </div> :
            <div>
                <img style={{width:'100%'}} src={aboutlogo}/>
                <p style={{textAlign:"center", margin:5}} className="about-tex">Balıkesir'de bakıcılık ve refakat hizmetleri sektöründe büyük bir
                    boşluğu doldurmak amacıyla kurulan firmamız, Sağlık sektöründeki 10 yıllık deneyimi ile hastalarınızı rahat ettirmek,
                    sevdiklerinizi iyi hissettirmek amacıyla profesyonel hayata adımını atmıştır. Profesyonel bakıcı ekibi, Balıkesir ve
                    çevresindeki sağlık hizmeti sektöründe eşsiz deneyimi ile sizleri hiç yormadan tam zamanlı, yarı zamanlı Hasta
                    Bakıcılık ve Refakatçilik Hizmeti vermekteyiz.Bize ulaşabileceğiniz telefon numaraları
                    <strong> 90 545 183 10 10 - 90 545 183 10 11</strong>

                </p>
            </div>
        }
        </div>
    );
}

const styles={
    image:{
        width: '50%',
        height: '50%',
        borderRadius : '1%',
    },
    text:{
        textAlign: 'center',
        color: '#000'
    },
    container:{
        marginTop: 50,
    }
}

export default About;
