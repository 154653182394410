import React, { useState } from 'react';

import '../styles/ContactButton.css'


const ContactButton = (props) => {

    return (

        <div id="testbutton" onClick={(e) => {
            e.preventDefault();
            window.location.href='/contact';
        }}></div>
    );
}

export default ContactButton;
