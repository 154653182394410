import React, { useState } from 'react';
import {
    Container,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText,
    Button,
} from 'react-bootstrap';
import '../styles/MobileNavbar.css'
import ContactButton from "./ContactButton";
import {MDBIcon} from "mdbreact";
import Logo from '../assets/KBEND-removebg.png'
import useWindowDimensions from "./useWindowDimensions";

const MobileNavi = (props) => {
    const { height, width } = useWindowDimensions();

    return (
        <div className="Mobile-header" style={{height: height * 15 /100}}>
            <div>
                <a href="/">
                    <img src={Logo} className="Mobile-logo" style={{width: width / 3 , height:height *10/100 , marginLeft:width/3 + 10}}/>
                </a>

                    <div className="Logos" style={{ marginTop:1}}>
                        <a href="https://www.instagram.com/balikesirkbdanismanlik/?hl=tr" >
                            <MDBIcon fab icon="instagram"/>
                        </a>
                        <a href="https://www.facebook.com/113341967517029/posts/113343757516850/?substory_index=0&sfnsn=scwspwa"
                        style={{marginLeft: 15}}>
                            <MDBIcon fab icon="facebook-f"/>
                        </a>
                        <a href="https://www.linkedin.com/company/balikesirkbdanismanlik">
                            <MDBIcon fab icon="linkedin-in" style={{marginLeft:15}} />
                        </a>
                    </div>
            </div>

        </div>
    );
}

export default MobileNavi;
