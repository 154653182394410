import React, { useState } from 'react';
import {
    Button,
    Form
} from 'react-bootstrap';

import '../styles/Navbar.css'
import Container from "react-bootstrap/Container";
import ContactForm from "../Components/ContactForm";
import Swal from 'sweetalert2'
import emailjs from 'emailjs-com';


const MainCarousel = (props) => {

    const [name,setName] = useState("");
    const [phone,setPhone] = useState("");
    const [msg,setMsg] = useState("");

    const Handler = (name) => {
        setName(name);
        console.log(name);
    };



    const send = (templateId, variables) => {
        window.emailjs.send(
            'gmail', templateId,
            variables
        ).then(res => {
            // Email successfully sent alert
            Swal.fire({
                title: 'Email Successfully Sent',
                icon: 'success'
            })
        })
            // Email Failed to send Error alert
            .catch(err => {
                Swal.fire({
                    title: 'Email Failed to Send',
                    icon: 'error'
                })
                console.error('Email Error:', err)
            })
    }

    return (
        <div>
            <ContactForm/>
            {/*<form>
            <h1>Hello {name}</h1>
            <p>Enter your name:</p>
            <input
                type='text'
                value={name}
                onChange={e => Handler(e.target.value)}
            />
            <input
                type='text'
                value={name}
                onChange={e => setName(e.target.value)}
            />
            <input
                type='text'
                value={name}
                onChange={e => setName(e.target.value)}
            />
        </form>

            <Container style={styles.containerForm}>
                <div style={styles.insideForm}>
    <Form>
        <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label style={{color:'#fff'}}>İsminiz</Form.Label>
            <Form.Control type="name" placeholder="İsminiz" value={name}
                          onChange={e => setName(e.target.value)} />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label style={{color:'#fff'}}>Example select</Form.Label>
            <Form.Control as="select">
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
            </Form.Control>
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlSelect2">
            <Form.Label>Example multiple select</Form.Label>
            <Form.Control as="select" multiple>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
            </Form.Control>
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Example textarea</Form.Label>
            <Form.Control as="textarea" rows={3} />
        </Form.Group>
    </Form>
                </div>
            </Container>*/}
</div>
    );
}

const styles={
    image:{
        width: '50%',
        height: '50%',
        borderRadius : '1%',
    },
    text:{
        textAlign: 'center',
        color: '#ffffff'
    },
    containerForm:{
        marginTop: 50,
        backgroundColor: '#4a0073'
    },
    insideForm:{
        margin: 50,
    }
}

export default MainCarousel;
