import {MDBCol, MDBContainer, MDBFooter, MDBRow, MDBIcon} from "mdbreact";
import React, { useState } from 'react';
import {
    Carousel,
    Container,
    Collapse,
} from 'react-bootstrap';
import Logo from '../assets/elderly-care.jpeg';
import Logo2 from '../assets/Japan-has-the-world’s-oldest-population.-Yet-it-dodged-a-coronavirus-crisis-at-elder-care-facilities..jpg';
import 'mdbreact/dist/css/mdb.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import useWindowDimensions from "./useWindowDimensions";
import iskur from '../assets/iskurlogo.gif';

const Footer = (props) => {

    const { height, width } = useWindowDimensions();

    return (
        <div>


            <MDBFooter style={{backgroundColor:'#120928'}} className="font-small pt-4 mt-4">
                <MDBContainer fluid className="text-center text-md-left">
                    <div className="icon-cards">
                        <MDBRow>
                            <MDBCol md="6">
                                <h5 className="title" style={{color: '#fff'}}>A</h5>
                                <p style={{color: '#fff'}}>
                                    KB Danışmanlık, Çalışma ve Sosyal Güvenlik Bakanlığı Türkiye iş Kurumu
                                    18/06/2021 tarih ve 8839402 sayılı izin belgesiyle 1255 no'lu belge ile  özel istihdam
                                    bürosu olarak faaliyet göstermektedir. 4904 sayılı Türkiye İş Kurumu Kanunu gereğince
                                    iş arayanlardan menfaat sağlanması ve ücret alınması yasaktır. Vaad ettiği hizmetler
                                    konusunda personel istihdam etmez, sağlık hizmeti sunmaz izinli kurumlar ile çözüm ortaklığı yapar.
                                </p>
                                <p>Şikayetleriniz için Balıkesir Çalışma ve İş Kurumu:  266 241 17 91 </p>
                                <p>Bizimle iletişim için:   545 183 10 10 - 545 183 10 11 </p>
                                <img src={iskur} alt=""/>
                            </MDBCol>
                            {}
                            {width > 700 ? <div>
                            <MDBCol md="2">

                                <h5 className="title" style={{color: '#fff' ,marginLeft:'30%'}}>Sosyal Medya</h5>
                                <ul>
                                    <li className="list-unstyled">
                                        <a href="https://www.instagram.com/balikesirkbdanismanlik/?hl=tr" style={{width:40}} className="ins-ic mr-3">
                                            <MDBIcon fab icon="instagram" />
                                        </a>
                                    </li>
                                    <a href="https://www.facebook.com/113341967517029/posts/113343757516850/?substory_index=0&sfnsn=scwspwa" className="fb-ic mr-3">
                                        <MDBIcon fab icon="facebook-f" />
                                    </a>
                                    <li className="list-unstyled">
                                        <a href="https://www.linkedin.com/company/balikesirkbdanismanlik" className="li-ic mr-3">
                                            <MDBIcon fab icon="linkedin-in" />
                                        </a>
                                    </li>
                                </ul>
                            </MDBCol>
                            <MDBCol md="2">
                                <h5 className="title" style={{color: '#fff' ,marginLeft:'30%'}}>Sayfalar</h5>
                                <ul>
                                    <li className="list-unstyled">
                                        <a href="/about" style={{width:40}} className="ins-ic mr-3" className="title">
                                            Hakkımızda
                                        </a>
                                    </li>
                                    <a href="/contact" className="fb-ic mr-3">
                                        İletişim
                                    </a>
                                    <li className="list-unstyled">
                                        <a href="/services" className="li-ic mr-3">
                                            İşlerimiz
                                        </a>
                                    </li>
                                </ul>
                            </MDBCol>
                                    </div> :  <div></div>}
                        </MDBRow>
                    </div>
                </MDBContainer>
                <div className="footer-copyright text-center py-3">
                    <MDBContainer fluid style={{color:'#fff'}}>
                        &copy; {new Date().getFullYear()} Copyright: <a href="https://www.balikesirkbdanismanlik.com"> KB Danışmanlık </a>
                    </MDBContainer>
                </div>
            </MDBFooter>
            {width<700 ? <div style={{height:70}}></div>: <div></div> }
        </div>
    );
}

const styles={
    image:{
        width: '50%',
        height: '50%',
        borderRadius : '1%',
    },
    text:{
        textAlign: 'center',
        color: '#000'
    },
    container:{
        marginTop: 50,
    }
}

export default Footer;



<MDBFooter color="blue" className="font-small pt-4 mt-4">
    <MDBContainer fluid className="text-center text-md-left">
        <div className="icon-cards">
            <MDBRow>
                <MDBCol md="6">
                    <h5 className="title" style={{color: '#fff'}}>Adres</h5>
                    <p style={{color: '#fff'}}>
                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat.
                    </p>
                </MDBCol>
                <MDBCol md="2">
                    <h5 className="title" style={{color: '#fff' ,marginLeft:'30%'}}>Links</h5>
                    <ul>
                        <li className="list-unstyled">
                            <a href="#!">Link 1</a>
                        </li>
                        <li className="list-unstyled">
                            <a href="#!">Link 2</a>
                        </li>
                        <li className="list-unstyled">
                            <a href="#!">Link 3</a>
                        </li>
                        <li className="list-unstyled">
                            <a href="#!">Link 4</a>
                        </li>
                    </ul>
                </MDBCol>
            </MDBRow>
        </div>
    </MDBContainer>
    <div className="footer-copyright text-center py-3">
        <MDBContainer fluid style={{color:'#fff'}}>
            &copy; {new Date().getFullYear()} Copyright: <a href="https://www.mdbootstrap.com"> KB Danışmanlık </a>
        </MDBContainer>
    </div>
</MDBFooter>
