import React, { useState } from 'react';

import '../styles/ContactForm.scss'
import {Form} from "react-bootstrap";
import Swal from 'sweetalert2'
import emailjs from 'emailjs-com';
import useWindowDimensions from "./useWindowDimensions";

const ContactForm = (props) => {

    const { height, width } = useWindowDimensions();

    const [name,setName] = useState("");
    const [phone,setPhone] = useState("");
    const [msg,setMsg] = useState("");

    const Handler = (name) => {
        setName(name);
        console.log(name);
    };



    function handleClick() {

        //Boş Olup olmadığı check edilecek bir de service id değişecek
        if (name === "" || phone === "" || msg === "") {
            Swal.fire({
                title: 'Lütfen Bütün Kutuları Doldurunuz',
                icon: 'error',
                confirmButtonColor: '#25053b'

            })
        } else {
            var data = {
                name: name,
                phone: phone,
                message: msg,
            };

            emailjs.send('service_v4d7edw', 'template_9juf6p4', data, 'user_3PHPdkSFTyZX6g3iLLfHW').then(
                Swal.fire({
                    title: 'En Kısa Sürede Sizinle İletişime Geçeceğiz',
                    icon: 'success',
                    confirmButtonColor: '#25053b'

                }).then(function() {
                    window.location.reload();
                })
            );
        }
    }

    return (

        <div className="form-container" >

            <div className="left" style={{marginLeft: '10%'}}>
                <div className="header">
                    <h2 className="animation a1">İletişim</h2>
                    <h4 className="animation a2">Lütfen iletişim bilgileriniz eksiksiz doldurunuz</h4>
                    <h4 className="animation a1">İş başvurularınızı iletisim@balikesirkbdanismanlik.com mail adresine yapabilirsiniz</h4>
                    <h4 className="animation a1">İletişim Numaralarımız: 90 545 183 10 10 - 90 545 183 10 11</h4>
                </div>
                <div className="form" >
                    <input type="name" className="form-field animation a3" placeholder="İsminiz" value={name}
                           onChange={e => setName(e.target.value)}/>
                        <input type="text" className="form-field animation a4" placeholder="Telefon Numaranız"value={phone}
                               onChange={e => setPhone(e.target.value)}/>

                    <Form>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Control as="textarea"  className="form-field animation a4" rows={3} placeholder="Mesajınız" value={msg}
                                          onChange={e => setMsg(e.target.value)}/>
                        </Form.Group>
                    </Form>
                            <button className="animation a6" onClick={()=>handleClick()}>Gönder</button>
                </div>

            </div>
            {width > 700 ? <div className="right"></div> : <div></div> }

        </div>

);
}

export default ContactForm;
