import React, { useState } from 'react';
import {
    Carousel,
    Container,
    Row, Col
} from 'react-bootstrap';
import '../styles/Navbar.css'
import '../styles/Services.css'
import icon1 from "../assets/icons/icons8-medical-doctor-96.png";
import icon2 from "../assets/icons/icons8-barber-scissors-80.png";
import icon3 from "../assets/icons/icons8-nurse-96.png";
import icon4 from "../assets/icons/icons8-ambulance-96.png";
import icon5 from "../assets/icons/icons8-looking-after-96.png";
import icon6 from "../assets/icons/icons8-therapy-96.png";
import icon7 from "../assets/icons/icons8-elderly-person-80.png"
import useWindowDimensions from "../Components/useWindowDimensions";


const Services = (props) => {
    const { height, width } = useWindowDimensions();
    return (
        <div>

            <Container style={styles.container}>
                <div className="icon-cards">
                    <h1 style={{textAlign:'center',textDecoration:'underline',marginBottom:30,color:'#000'}}>Servislerimiz</h1>
                    {width > 900 ? <div>
                    <Row style={{marginTop:40,marginBottom: 20,}}>
                        <Col>

                        </Col>
                        <Col>
                            <img className="icon-image" src={icon7}/>
                            <h3 style={{textAlign:'center',textDecoration:'underline',marginBottom:30,color:'#000'}}>Yaşlı Bakım</h3>
                            <p className="card-text">
                                Doğduğumuz gün yaşlanmaya başlıyoruz. Yaş aldıkça ihtiyaç duyduğumuz destek artıyor.
                                Yaşlı bakıcı ya da hasta bakıcı hizmetini yaşlı, hasta bakım uzmanları, palyatif bakım uzmanı,
                                yaşlı bakıcı veya hemşirelik eğitimi almış alternatifler içerisinden aradığınıza göre şekillendirebiliriz.
                            </p>
                        </Col>
                        <Col>

                        </Col>
                    </Row>
                    <Row>
                        <Col >
                            <img className="icon-image" src={icon1}/>
                            <h3 style={{textAlign:'center',textDecoration:'underline',marginBottom:30,color:'#000'}}>Evde Doktor</h3>
                            <p className="card-text">
                                Geçici veya uzun süreli sağlık sorunlarınız ve palyatif bakım için evde sağlık ihtiyaçlarınıza
                                yanıt verecek uzman doktorlardan geçici evde sağlık hizmetlerini,
                                Evde doktor muayene ve tedavi önerilerini bizim danışmanlığımız da ulaşabilirsiniz.
                            </p>
                        </Col>
                        <Col>
                            <img className="icon-image" src={icon2} style={{height:96}}/>
                            <h3 style={{textAlign:'center',textDecoration:'underline',marginBottom:30,color:'#000'}}>Evde Kuaför</h3>
                            <p className="card-text">
                                Kişisel bakım her yaşta her sağlık durumunda önemlidir, ve yaşam kalitesi üzerine olumlu etkilere sahiptir.
                                Evde bakıcı ihtiyacı olan ya da evden çıkamayan bireylerin hijyen,
                                saç bakım ve kuaförlük hizmetlerini sizin aradığınıza göre bizimle alabilirsiniz
                            </p>
                        </Col>
                        <Col>
                            <img className="icon-image" src={icon3}/>
                            <h3 style={{textAlign:'center',textDecoration:'underline',marginBottom:30,color:'#000'}}>Evde Hemşire</h3>
                            <p className="card-text">
                                Geçici veya uzun süreli sağlık problemleriniz, ameliyat sonrası bakım ve hemşirelik hizmetleri
                                yaşam kalitemiz için çok önemlidir. Özel hemşire hizmeti Türk veya Yabancı
                                hemşireler üzeriden yarı-zamanı ve tam zamanlı alternatifler içerisinden aradığınıza göre şekillendirebiliriz
                            </p>
                        </Col>
                    </Row>

                    <Row style={{marginTop:40,}}>
                        <Col>
                            <img className="icon-image" src={icon4}/>
                            <h3 style={{textAlign:'center',textDecoration:'underline',marginBottom:30,color:'#000'}}>Yaşlı - Engelli Transferi</h3>
                            <p className="card-text">
                                Yaşlı ve engelli yakınlarınızın bir yerden bir
                                yere transferinde tam-zamanlı, yarı zamanlı
                                veya geçici şoför ve transfer çözümleri
                            </p>
                        </Col>
                        <Col>
                            <img className="icon-image" src={icon5}/>
                            <h3 style={{textAlign:'center',textDecoration:'underline',marginBottom:30,color:'#000'}}>Hastanede Refakatçi</h3>
                            <p className="card-text">
                                 Firmamız tarafından verilecek personel hastanızın tedavi sürecinde tüm ihtiyaçlarını eksiksiz ve
                                hatasız bir şekilde karşılayabilecek, sizlerin de hastanızın bakımı noktasından gözünüz arkada kalmayacaktır.
                                Bakım personellerimizde olduğu gibi hastane refakatçisi personelimizin de hasta ve yaşlı bakımı konusunda
                                eğitimi ve sertifikası olması da içinizin rahat etmesini sağlayacaktır.
                            </p>
                        </Col>
                        <Col>
                            <img className="icon-image" src={icon6}/>
                            <h3 style={{textAlign:'center',textDecoration:'underline',marginBottom:30,color:'#000'}}>Evde Psikolog ve Fizyoterapist</h3>
                            <p className="card-text">
                                Beden ve ruh bir bütündür. Bu bütünlüğün bir evde psikolog ve evde fizyoterapist ile
                                geliştirilmesi yaşam kalitesini arttıracaktır. Bu desteği evden dışarı çıkmakta güçlük yaşayan yakınlarınıza evlerinde
                                sizin aradığınıza göre, bizim danışmanlığımızda alabilirsiniz.
                            </p>
                        </Col>
                    </Row>
                    </div>:


                        <div>

                            <img className="icon-image" src={icon7}/>
                            <h3 style={{textAlign:'center',textDecoration:'underline',marginBottom:30,color:'#000'}}>Yaşlı Bakım</h3>
                            <p className="card-text">
                                Doğduğumuz gün yaşlanmaya başlıyoruz. Yaş aldıkça ihtiyaç duyduğumuz destek artıyor.
                                Yaşlı bakıcı ya da hasta bakıcı hizmetini yaşlı, hasta bakım uzmanları, palyatif bakım uzmanı,
                                yaşlı bakıcı veya hemşirelik eğitimi almış alternatifler içerisinden aradığınıza göre şekillendirebiliriz.
                            </p>

                            <img className="icon-image" src={icon1}/>
                            <h3 style={{textAlign:'center',textDecoration:'underline',marginBottom:30,color:'#000'}}>Evde Doktor</h3>
                            <p className="card-text">
                                Geçici veya uzun süreli sağlık sorunlarınız ve palyatif bakım için evde sağlık ihtiyaçlarınıza
                                yanıt verecek uzman doktorlardan geçici evde sağlık hizmetlerini,
                                Evde doktor muayene ve tedavi önerilerini bizim danışmanlığımız da ulaşabilirsiniz.
                            </p>

                            <img className="icon-image" src={icon3}/>
                            <h3 style={{textAlign:'center',textDecoration:'underline',marginBottom:30,color:'#000'}}>Evde Hemşire</h3>
                            <p className="card-text">
                                Geçici veya uzun süreli sağlık problemleriniz, ameliyat sonrası bakım ve hemşirelik hizmetleri
                                yaşam kalitemiz için çok önemlidir. Özel hemşire hizmeti Türk veya Yabancı
                                hemşireler üzeriden yarı-zamanı ve tam zamanlı alternatifler içerisinden aradığınıza göre şekillendirebiliriz
                            </p>

                            <img className="icon-image" src={icon2} style={{height:96}}/>
                            <h3 style={{textAlign:'center',textDecoration:'underline',marginBottom:30,color:'#000'}}>Evde Kuaför</h3>
                            <p className="card-text">
                                Kişisel bakım her yaşta her sağlık durumunda önemlidir, ve yaşam kalitesi üzerine olumlu etkilere sahiptir.
                                Evde bakıcı ihtiyacı olan ya da evden çıkamayan bireylerin hijyen,
                                saç bakım ve kuaförlük hizmetlerini sizin aradığınıza göre bizimle alabilirsiniz
                            </p>

                            <img className="icon-image" src={icon4}/>
                            <h3 style={{textAlign:'center',textDecoration:'underline',marginBottom:30,color:'#000'}}>Yaşlı - Engelli Transferi</h3>
                            <p className="card-text">
                                Yaşlı ve engelli yakınlarınızın bir yerden bir
                                yere transferinde tam-zamanlı, yarı zamanlı
                                veya geçici şoför ve transfer çözümleri
                            </p>

                            <img className="icon-image" src={icon5}/>
                            <h3 style={{textAlign:'center',textDecoration:'underline',marginBottom:30,color:'#000'}}>Hastanede Refakatçi</h3>
                            <p className="card-text">
                                Firmamız tarafından verilecek personel hastanızın tedavi sürecinde tüm ihtiyaçlarını eksiksiz ve
                                hatasız bir şekilde karşılayabilecek, sizlerin de hastanızın bakımı noktasından gözünüz arkada kalmayacaktır.
                                Bakım personellerimizde olduğu gibi hastane refakatçisi personelimizin de hasta ve yaşlı bakımı konusunda
                                eğitimi ve sertifikası olması da içinizin rahat etmesini sağlayacaktır.
                            </p>

                            <img className="icon-image" src={icon6}/>
                            <h3 style={{textAlign:'center',textDecoration:'underline',marginBottom:30,color:'#000'}}>Evde Psikolog ve Fizyoterapist</h3>
                            <p className="card-text">
                                Beden ve ruh bir bütündür. Bu bütünlüğün bir evde psikolog ve evde fizyoterapist ile
                                geliştirilmesi yaşam kalitesini arttıracaktır. Bu desteği evden dışarı çıkmakta güçlük yaşayan yakınlarınıza evlerinde
                                sizin aradığınıza göre, bizim danışmanlığımızda alabilirsiniz.
                            </p>
                    </div> }

                </div>

            </Container>
        </div>



    );
}

const styles={
    image:{
        width: '50%',
        height: '50%',
        borderRadius : '1%',
    },
    text:{
        textAlign: 'center',
        color: '#000'
    },
    container:{
        marginTop: 50,
        marginBottom: 100,
    }
}

export default Services;
