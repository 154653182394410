import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './styles/Header.css'
import Navi from "./Components/Navi";
import {Carousel,
Col,Row} from "react-bootstrap";
import {
    BrowserRouter,
    Switch,
    Route,
    Link
} from "react-router-dom";

import styled, { keyframes } from 'styled-components';
import { fadeInLeft, fadeInRight } from 'react-animations';

import About from "./Screens/About";
import Main from "./Screens/Main";
import Services from "./Screens/Services";
import Contact from "./Screens/Contact";
import Footer from "./Components/Footer";
import useWindowDimensions from "./Components/useWindowDimensions";
import MobileNavi from "./Components/MobileNavi";
import MobileFooter from "./Components/MobileFooter";

const right = keyframes`${fadeInRight}`;
const bounceAnimation = keyframes`${fadeInLeft}`;

const BouncyDiv = styled.div`
  animation: 5s ${bounceAnimation};
`;

const FadeRightDiv = styled.div`
  animation: 5s ${right};
`;

function App() {

    const { height, width } = useWindowDimensions();
    useEffect(() => {

        document.title = `KB Danışmanlık`;
    });

    return (

      <div>
          <title>KB Danışmanlık</title>

          {width < 1000 ? <MobileNavi/> : <Navi/>}

            <BrowserRouter>
                <div>
                    {/*  <div className="header">
              <NavLink exact activeClassName="active" to="/">Home</NavLink>
              <NavLink exact activeClassName="active" to="/todos">todos</NavLink>
              <NavLink activeClassName="active" to="/login">Login</NavLink><small>(Access without token only)</small>
              <NavLink activeClassName="active" to="/about">About</NavLink><small>(Access with token only)</small>
            </div> */}
                    <div>
                        <Switch>
                            <Route exact path="/" component={Main} />
                            <Route path="/about" component={About} />
                            <Route path="/services" component={Services} />
                            <Route path="/contact" component={Contact} />

                        </Switch>
                    </div>
                </div>
            </BrowserRouter>

          <Footer/>
          {width < 900 ? <MobileFooter/>: <div/>}




        </div>


  );
}

export default App;
